import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Section, Title } from "../../components/Core";

const Mission = () => (
  <>
    <div className="overflow-hidden">
      <Section bg="greyBackground">
        <Container>
          <Row className="align-items-center">
            <Col lg="12" className="order-lg-1 mt-5 mt-lg-0">
              <div
                data-aos="fade-bottom"
                data-aos-duration="750"
                data-aos-once="true"
              >
                <div>
                  <Title variant="quote" color="ash" className='text-center'>
                    Our mission is to simplify and reduce the barriers to an exceptional Maximo experience.
                  </Title>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </Section>
    </div>
  </>
);

export default Mission;
