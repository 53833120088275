import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";

import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import { Title, Section, Box, Text } from "../../components/Core";

const SectionStyled = styled(Section)``;

const SingleImage = styled(Box)`
  display: flex;
  flex-direction: column;
  img {
    border-radius: 8px;
    object-fit: cover;
  }
`;

export const imageFormat = graphql`
  fragment imageFormat on File {
    childImageSharp {
      fixed(width: 220) {
        ...GatsbyImageSharpFixed
      }
    }
  }
`

const WhoWeAre = () => {

  const data = useStaticQuery(graphql`
    query {
      rail: file(relativePath: { eq: "rail.jpg" }) {
        ...imageFormat
      }

      pipes: file(relativePath: { eq: "pipes.jpg" }) {
        ...imageFormat
      }

      power_lines: file(relativePath: { eq: "power_lines.jpg" }) {
        ...imageFormat
      }

      factory: file(relativePath: { eq: "factory.jpg" }) {
        ...imageFormat
      }
    }
  `)

  return (
    <>
      {/* <!-- Content2 section --> */}
      <SectionStyled>
        <Container>
          <Row className="align-items-center">
            <Col lg="6" className="mb-4 mb-lg-0">
              <Box className="d-flex">
                <SingleImage>
                  <Img
                    fixed={data.rail.childImageSharp.fixed}
                    alt=""
                    className="mb-3 mr-3"
                    data-aos="zoom-in"
                    data-aos-duration="500"
                    data-aos-once="true"
                  />
                  <Img
                    fixed={data.pipes.childImageSharp.fixed}
                    alt=""
                    className="mb-3 mr-3"
                    data-aos="zoom-in"
                    data-aos-duration="500"
                    data-aos-once="true"
                  />
                </SingleImage>
                <SingleImage>
                 <Img
                    fixed={data.power_lines.childImageSharp.fixed}
                    alt=""
                    className="mb-3 mr-3"
                    data-aos="zoom-in"
                    data-aos-duration="500"
                    data-aos-once="true"
                  />
                  <Img
                    fixed={data.factory.childImageSharp.fixed}
                    alt=""
                    className="mb-3 mr-3"
                    data-aos="zoom-in"
                    data-aos-duration="500"
                    data-aos-once="true"
                  />
                </SingleImage>
              </Box>
            </Col>
            <Col lg="6" md={9}>
              <div>
                <Title>
                  This is who we are.
                </Title>
                <Text className="pb-4">
                  We specialize in developing and delivering exceptional solutions for IBM&reg; Maximo&reg;.
                </Text>
                <Text className="pb-4">
                  Across a wide range of industries, from transit to power generation to manufacturing and many others, 
                  we have deployed solutions that enhance the operational effectiveness and efficiency of our clients.
                </Text>
              </div>
            </Col>
          </Row>
        </Container>
      </SectionStyled>
    </>
  );
};

export default WhoWeAre;
