import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import { Title, Box } from "../../components/Core";

const WhyWeAreHere = () => (
    <>
        <Box bg="greyBackground" className="position-relative" py={[4, null, null, null, 5]}>
            <Container>
                <Row className="justify-content-center">
                    <Col lg="10">
                        <Box className="text-center">
                            <Title variant="quote" color="ash">
                                We have a vision for effective asset management processes and better business 
                                decisions realized simply and reliably. 
                            </Title>
                        </Box>
                    </Col>
                </Row>
            </Container>
        </Box>
    </>
);

export default WhyWeAreHere;
