import React from "react";
import imgMetaCardSquare from "../assets/images/card_homepage_square.jpg";
import imgMetaCardTwitter from "../assets/images/card_homepage_twitter.jpg";
import PageWrapper from "../components/PageWrapper";
import Seo from "../components/Seo";
import Hero from "../sections/landing/Hero";
import Mission from "../sections/landing/Mission";
import WhatWeDo from "../sections/landing/WhatWeDo";
import WhatWeValue from "../sections/landing/WhatWeValue";
import WhoWeAre from "../sections/landing/WhoWeAre";
import WhyWeAreHere from "../sections/landing/WhyWeAreHere";


const IndexPage = (props) => {
  return (
    <>
      <PageWrapper footerDark>
        <Seo
          title="Sharptree | Opqo | Mobile Reimagined"
          description="Sharptree reimagines the mobile Maximo experience. Opqo is an elegant mobile application that is simple to buy, easy to deploy, and delightful to use. "
          image={imgMetaCardSquare}
          twitterImage={imgMetaCardTwitter}
          pathname={props.location.pathname}
        />
        <Hero />
        <WhoWeAre />
        <WhyWeAreHere />
        <WhatWeValue />
        <Mission />
        <WhatWeDo />
      </PageWrapper>
    </>
  );
};
export default IndexPage;
