import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Box, Section, Title } from "../../components/Core";
import HeaderBackground from "../../components/HeaderBackground";

const Hero = () => {
  return (
    <>
      {/* <!-- Hero Area --> */}
      <HeaderBackground>
        <Section>
          <Container>
            <Row>
              <Col
                md="11"
                lg="9"
                className="order-lg-1"
                data-aos="fade-right"
                data-aos-duration="750"
                data-aos-delay="500"
                data-aos-once="true"
              >
                <Box py={[null, null, null, 6]} pr={5} pt={[4, null]}>
                  <Title variant="hero">
                    A simpler approach to Maximo and mobility.
                </Title>
                </Box>
              </Col>
            </Row>
          </Container>
        </Section>
      </HeaderBackground>
    </>
  );
};

export default Hero;
