import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";

import { Title, Span, Section, Box, Text } from "../../components/Core";

import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const ImageRight = styled(Box)`
  img {
    box-shadow: ${({ theme }) => `0 52px 54px ${theme.colors.shadow}`};
    border-radius: 10px;
  }
`;

const WhatWeValue = () => {
  
  const data = useStaticQuery(graphql`
    query {
      rowing: file(relativePath: { eq: "rowing.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <>
      {/* <!-- Content section 1 --> */}
      <Section>
        <Container>
          <Row className="align-items-center">
            <Col lg="5" md="10" className="order-lg-2 mb-4">
              <div className="position-relative text-lg-right">
                <ImageRight
                  className=""
                  data-aos="fade-down"
                  data-aos-duration="750"
                  data-aos-once="true"
                >
                  <Img fluid={data.rowing.childImageSharp.fluid} />
                </ImageRight>
              </div>
            </Col>
            <Col lg="6" className="order-lg-1 mt-5 mt-lg-0 mr-5">
              <div
                data-aos="fade-right"
                data-aos-duration="750"
                data-aos-once="true"
              >
                <div>
                  <Title>This is what we value.</Title>
                  <Text className="pb-3">
                    Our experience has shown that truly successful Maximo implementations come from close relationships,
                    working together to understand your goals and challenges and investing in your success.
                  </Text>
                  <Text className="pb-3">
                    For this to be possible, these relationships must be based on
                    <Span color="primaryPurple"><strong> trust, honesty, transparency, collaboration and quality</strong></Span>.
                  </Text>
                  <Text className="pb-3">
                    These are the values we believe in, and these are the values we are building our business on.
                  </Text>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </Section>
    </>
  );
};

export default WhatWeValue;
